/*
 * @Description: 这里写你类描述
 * @Author: YYQ
 * @Date: 2023-09-16 13:12:18
 * @LastEditors: YYQ
 * @LastEditTime: 2024-06-02 00:31:11
 */

import React, { useEffect, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import Header from '@/components/Header';
import routes from '@/routes';
import Layout from '@/layout';
import useStateIfMounted from '@/utils/usim';
import { message } from 'antd'

const AppRouter = () => {
  useEffect(() => {
    const handleOnlineStatus = () => {
      if (!navigator.onLine) {
        // message.warning('无网络');
      }
    };
    window.addEventListener('online', handleOnlineStatus);
    window.addEventListener('offline', handleOnlineStatus);
    return () => {
      window.removeEventListener('online', handleOnlineStatus);
      window.removeEventListener('offline', handleOnlineStatus);
    };
  }, [])

  return (
    <div id='body'>
      <Router>
        <Switch>
          <Route key='/' path='/' exact render={() => <Redirect to="/home" />} />
          <Layout>
            <Suspense fallback={null}>
              {/* <Header /> */}
              {routes
              .map(({ path, Component }) => ( 
                <Route
                  key={path}
                  path={path}
                  render={
                    (props: any) =>
                    <div className='pcDevice'>
                      <Component {...props} useState={useStateIfMounted} />
                    </div>
                  }
                />
              ))}1
            </Suspense>
          </Layout>
        </Switch>
      </Router>
    </div>
  )
};
export default AppRouter