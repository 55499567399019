/*
 * @Description: 这里写你类描述
 * @Author: YYQ
 * @Date: 2023-07-23 23:23:30
 * @LastEditors: Lukey
 * @LastEditTime: 2023-10-24 21:24:24
 */

import React, { Suspense, useMemo } from 'react';
import routes from '@/routes';
import NotFound from '@/page/notfound/index';
import '@/page/global.scss';


const Layout = ({ children, location }: {
  children?: any;
  location?: any;
}) => {
  const pathname = location?.pathname;
  const isRouteExist = useMemo(() => routes.some(item => item.path.toLocaleLowerCase().trim().replace(new RegExp('/', 'gm'), '') === pathname?.toLocaleLowerCase().trim().replace(new RegExp('/', 'gm'), '')), [routes, pathname]);

  return (
    <React.Fragment>
      {isRouteExist ? (
        <div>
          <Suspense fallback={null}>
            {children}
          </Suspense>
        </div>
      ) : (
        <NotFound />
      )}
    </React.Fragment>
  )
};

export default Layout;