/*
 * @Description: 这里写你类描述
 * @Author: YYQ
 * @Date: 2023-10-25 11:17:45
 * @LastEditors: YYQ
 * @LastEditTime: 2023-10-26 15:02:43
 */

import React from "react";
const Home = React.lazy(()=>import('@/page/Home/index'));
const Collectionsrdinals = React.lazy(()=>import('@/page/Collectionsrdinals/index'));

const routes = [
    {path: "/home", Component: Home},
    {path: "/collectionsrdinals", Component: Collectionsrdinals},
] as const; 
export default routes;