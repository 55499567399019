/*
 * @Description: 这里写你类描述
 * @Author: YYQ
 * @Date: 2023-07-25 23:18:42
 * @LastEditors: Lukey
 * @LastEditTime: 2023-10-24 21:29:13
 */
import React from 'react';
import ReactDOM from "react-dom/client"
import AppRouter from './AppRouter'
import '@/page/global.scss';
import '@/page/global.module.scss';
import { StyleProvider } from '@ant-design/cssinjs';
global.Buffer = global.Buffer || require('buffer').Buffer

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(  
    <StyleProvider hashPriority='low'>
        <AppRouter />
    </StyleProvider>
)